import React, { useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, isCheckingAuth } = useAuth();
  const [showLoading, setShowLoading] = useState(true);
  const redirectAttempts = useRef(0);
  const timeoutRef = useRef(null);

  // Debug log to track authentication state
  useEffect(() => {
    console.log("ProtectedRoute - Authentication State:", isAuthenticated);
    console.log("ProtectedRoute - Checking Auth:", isCheckingAuth);

    // Add a timeout to avoid being stuck in the loading state
    if (isCheckingAuth) {
      // Clear previous timeout if exists
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        console.log("ProtectedRoute - Forced loading timeout");
        setShowLoading(false);
      }, 3000); // Show loading for max 3 seconds
    } else {
      // Clear timeout when not checking anymore
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isAuthenticated, isCheckingAuth]);

  // If auth check is in progress and within loading window
  if (isCheckingAuth && showLoading) {
    console.log("ProtectedRoute - In loading state");
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}>
        <h3>Checking authentication...</h3>
        <p>Please wait while we verify your credentials.</p>
      </div>
    );
  }

  // Only redirect if we're sure the user is not authenticated
  // and not just waiting for the check to complete
  if (!isAuthenticated) {
    console.log("ProtectedRoute - Not authenticated, redirecting");
    // Track redirects to prevent potential loops
    redirectAttempts.current += 1;

    // Safety check - if we've tried to redirect too many times, just show error
    if (redirectAttempts.current > 2) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column'
        }}>
          <h3>Authentication Error</h3>
          <p>There seems to be an issue with authentication. Please try logging in again.</p>
          <a href="/" className="btn btn-primary mt-3">Go to Home</a>
        </div>
      );
    }

    return <Navigate to="/" replace />;
  }

  // User is authenticated, render the children
  console.log("ProtectedRoute - Authenticated, showing protected content");
  return children;
}