import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { ApiClient } from './ApiClient';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const apiClient = useMemo(() => new ApiClient(), []);

  useEffect(() => {
    let isMounted = true;

    const checkAuthentication = async () => {
      console.log("AuthContext - Starting authentication check");

      try {
        const response = await apiClient.authCheck();
        console.log("AuthContext - Auth check response:", response.data);
        if (isMounted) {
          setIsAuthenticated(response.data.isAuthenticated);
          setIsCheckingAuth(false);
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        if (isMounted) {
          setIsAuthenticated(false);
          setIsCheckingAuth(false);
        }
      }
    };

    // Only run auth check if we're still in checking state
    if (isCheckingAuth) {
      checkAuthentication();
    }

    return () => {
      isMounted = false;
    };
  }, [apiClient, isCheckingAuth]);

  // Debug log when auth state changes
  useEffect(() => {
    console.log("AuthContext - isAuthenticated state:", isAuthenticated);
  }, [isAuthenticated]);

  const handleLogout = async () => {
    await apiClient.logOut();
    setIsAuthenticated(false);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      handleLogout,
      isCheckingAuth,
      apiClient // Expose the apiClient so components can use the same instance
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);