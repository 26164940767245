import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import bgImage from "../assets/bg.jpg";
import Config from "../Config";
import styles from "./HomePage.module.css";

export default function HomePage() {
  const [isLandscape, setIsLandscape] = useState(false);

  //domain clubeventcal.com

  // useEffect(() => {
  //   //functions here run on page load
  //   googleCalList();
  // }, []);

  // Check if device is in landscape mode
  useEffect(() => {
    const checkOrientation = () => {
      setIsLandscape(window.innerHeight < 500 && window.innerWidth > window.innerHeight);
    };

    // Initial check
    checkOrientation();

    // Add event listener for orientation changes
    window.addEventListener('resize', checkOrientation);

    // Cleanup
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  // Set background image using CSS class instead of direct DOM manipulation
  useEffect(() => {
    // Add background class
    document.body.classList.add(styles.homepageBody);

    // Set background image with CSS
    const bodyStyle = document.body.style;
    bodyStyle.backgroundImage = `url(${bgImage})`;

    return () => {
      document.body.classList.remove(styles.homepageBody);
      bodyStyle.backgroundImage = '';
    };
  }, []);

  // Get API URL from Config
  const apiUrl = Config.apiUrl || window.location.origin;
  const stravaAuthUrl = `${apiUrl}/authorize?platform=strava`;

  return (
    <Container fluid className={styles.container}>
      {isLandscape ? (
        // Landscape mode
        <div className={styles.landscapeContainer}>
          <Row className="justify-content-center text-center mx-0 w-100">
            <Col xs={11} sm={10} md={8} lg={6} className={styles.contentBox}>
              <h2 className={styles.heading}>Empower Your Fitness Journey</h2>
              <h4 className={styles.subheading}>Sync Strava Club Events with Ease!</h4>
              <p className={styles.paragraph}>
                Welcome to Club Event Cal, where we bridge the gap between your passion
                for fitness and the ease of staying organized. Our seamless integration
                allows you to import Strava Club Events directly into your Google Calendar,
                ensuring you never miss a workout or group activity again. Join the Club Event
                Cal community today and revolutionize the way you plan, track, and conquer your fitness goals!
              </p>
              <a
                href={stravaAuthUrl}
                className={styles.cta}
                onClick={() => {
                  // Log click events to help diagnose issues
                  console.log('Strava button clicked');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Connect with Strava"
                  src="/connect_strava.png"
                  width="193"
                  height="50"
                  loading="lazy"
                  className={styles.ctaImage}
                />
              </a>
            </Col>
          </Row>
        </div>
      ) : (
        // Portrait mode
        <div className={styles.portraitContainer}>
          <Row className="justify-content-center text-center mx-0 w-100">
            <Col xs={11} sm={10} md={8} lg={6} className={styles.contentBox}>
              <h2 className={styles.heading}>Empower Your Fitness Journey</h2>
              <h4 className={styles.subheading}>Sync Strava Club Events with Ease!</h4>
              <p className={styles.paragraph}>
                Welcome to Club Event Cal, where we bridge the gap between your passion
                for fitness and the ease of staying organized. Our seamless integration
                allows you to import Strava Club Events directly into your Google Calendar,
                ensuring you never miss a workout or group activity again. Join the Club Event
                Cal community today and revolutionize the way you plan, track, and conquer your fitness goals!
              </p>
              <a
                href={stravaAuthUrl}
                className={styles.cta}
                onClick={() => {
                  // Log click events to help diagnose issues
                  console.log('Strava button clicked');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Connect with Strava"
                  src="/connect_strava.png"
                  width="193"
                  height="50"
                  loading="lazy"
                  className={styles.ctaImage}
                />
              </a>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
}
