import axios from "axios";
import Config from "./Config";

// Get API URL - if we're in local development, use relative paths to leverage proxy
const isLocalDev = window.location.hostname === 'localhost';
const apiUrl = isLocalDev ? '' : Config.apiUrl;

export class ApiClient {
  // constructor(action) {
  // this.token = getCookie("token");
  // this.uid = getCookie("uid");
  // this.action = action;
  // this.formData = new FormData();
  // this.formData.append("token", this.token);
  // this.formData.append("action", action);
  // this.formData.append("uid", this.uid);
  // }

  constructor() {
    console.log("ApiClient - Initializing with baseURL:", apiUrl || 'relative paths (using proxy)');

    // Create axios instance with default configurations
    this.axiosInstance = axios.create({
      baseURL: apiUrl,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Add request and response interceptors for debugging
    this.axiosInstance.interceptors.request.use(
      config => {
        console.log(`ApiClient - Making ${config.method.toUpperCase()} request to ${config.url}`);
        return config;
      },
      error => {
        console.error("ApiClient - Request error:", error);
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      response => {
        console.log(`ApiClient - Response from ${response.config.url}:`, response.status);
        return response;
      },
      error => {
        // Handle CORS errors specifically
        if (error.message && error.message.includes('Network Error')) {
          console.error("ApiClient - CORS or network error detected. This might be due to cookies not being sent correctly.");
          // You might want to redirect to auth page or show a specific message
        }
        console.error("ApiClient - Response error:", error.response ? error.response.status : error.message);
        return Promise.reject(error);
      }
    );
  }

  async apiCall(method, url, data) {
    try {
      // Use the axios instance for all API calls
      const response = await this.axiosInstance({
        method,
        url,
        data
      });
      return response;
    } catch (error) {
      console.log(error + " ERROR");

      // If we get an unauthorized error (401) from the server, redirect to Strava auth
      if (error.response && error.response.status === 401) {
        console.log("ApiClient - Unauthorized, redirecting to Strava auth");
        window.location.href = this.getStravaAuthUrl();
        return null;
      }

      throw error;
    }
  }

  authCheck() {
    console.log("ApiClient - Calling authCheck");
    return this.apiCall("GET", `/auth_check`, "")
      .catch(error => {
        console.error("Auth check failed:", error);
        // Return a default response to prevent app crashes
        return { data: { isAuthenticated: false } };
      });
  }

  getSettings() {
    return this.apiCall("GET", `/settings`, "")
  }

  googleCal() {
    return this.apiCall("GET", `/calendars`, "")
  }

  logOut() {
    return this.apiCall("POST", `/auth_check`, "")
  }

  stravaClub() {
    return this.apiCall("GET", `/clubs`, "")
  }

  saveSettings(body) {
    return this.apiCall("POST", `/settings`, body)
  }

  // Get the URL to initiate Strava auth - need absolute URL here
  getStravaAuthUrl() {
    const fullApiUrl = Config.apiUrl || (window.location.origin);
    return `${fullApiUrl}/authorize?platform=strava`;
  }

  // Function to get the OAuth URL with specified callback
  getAuthUrl(platform) {
    // Always need absolute URLs for OAuth flows
    const fullApiUrl = Config.apiUrl || (window.location.origin);
    const callbackUrl = encodeURIComponent(`${window.location.origin}/auth_callback`);
    return `${fullApiUrl}/authorize?platform=${platform}&redirect_uri=${callbackUrl}`;
  }
}
