import React, { useEffect, useState } from "react";
import { Button, Table, Card, Row, Col, Alert } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ApiClient } from "../../ApiClient";
import Form from "react-bootstrap/Form";

export default function Wizard({ stravaClubList, calendarList }) {
  const [progress, setProgress] = useState(0);
  const [cal, setCal] = useState({ id: "", name: "" });
  const [club, setClub] = useState({ id: "", name: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pages, setPages] = useState({
    page1: true,
    page2: false,
    page3: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const apiClient = new ApiClient();
  const totalPages = 3;

  const nextPage = () => {
    if (pageNo >= totalPages) {
      submitHandler();
    } else {
      increasePageNo();
    }
  };

  const goToPrev = () => {
    if (pageNo <= 1) {
      // Already at first page, do nothing
      return;
    } else {
      decreasePageNo();
    }
  };

  const increasePageNo = () => {
    const newPageNo = pageNo + 1;
    setPageNo(newPageNo);

    // Update which page is visible
    const newPages = { ...pages };
    newPages[`page${pageNo}`] = false;
    newPages[`page${newPageNo}`] = true;
    setPages(newPages);
  };

  const decreasePageNo = () => {
    const newPageNo = pageNo - 1;
    setPageNo(newPageNo);

    // Update which page is visible
    const newPages = { ...pages };
    newPages[`page${pageNo}`] = false;
    newPages[`page${newPageNo}`] = true;
    setPages(newPages);
  };

  const submitHandler = async (e) => {
    if (e) e.preventDefault();

    if (!cal.id || !club.id) {
      setSubmitError("Please select both a calendar and a club before confirming.");
      return;
    }

    setIsSubmitting(true);
    setSubmitError("");

    try {
      const body = JSON.stringify({
        calendar_id: cal.id,
        club_id: club.id,
        calendar_name: cal.name,
        club_name: club.name,
      });
      console.log("Save Settings Request Body: ", body);
      const res = await apiClient.saveSettings(body);
      if (res.status === 200) {
        setSubmitSuccess(true);
        // Automatically reload after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      setSubmitError("An error occurred while saving settings. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCalChange = (e, name) => {
    setCal({ id: e.target.value, name: name });
  };

  const handleClubChange = (e, name) => {
    setClub({ id: e.target.value, name: name });
  };

  useEffect(() => {
    setProgress((pageNo / totalPages) * 100);
  }, [pageNo, totalPages]);

  const isNextDisabled = () => {
    if (pageNo === 1 && !cal.id) return true;
    if (pageNo === 2 && !club.id) return true;
    return false;
  };

  if (submitSuccess) {
    return (
      <div className="wizard-container">
        <Alert variant="success" className="text-center py-4">
          <Alert.Heading>Settings Saved Successfully!</Alert.Heading>
          <p className="mb-0">
            Your calendar and club settings have been saved. The page will refresh automatically.
          </p>
        </Alert>
      </div>
    );
  }

  return (
    <div className="wizard-container">
      <ProgressBar
        now={progress}
        variant="success"
        className="mb-4"
        style={{ height: "10px", borderRadius: "5px" }}
      />

      {submitError && (
        <Alert variant="danger" className="mb-4" onClose={() => setSubmitError("")} dismissible>
          {submitError}
        </Alert>
      )}

      <Form id="wizard-form" onSubmit={(e) => submitHandler(e)}>
        {pages.page1 && (
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="mb-3">Select Google Calendar</Card.Title>
              <Card.Text>
                Choose the Google Calendar where you want your Strava club events to appear:
              </Card.Text>

              {calendarList.length > 0 ? (
                <div className="calendar-options mt-3" style={{ maxHeight: "300px", overflowY: "auto" }}>
                  {calendarList.map((data, i) => (
                    <Form.Check
                      key={i}
                      type="radio"
                      id={`calendar-${i}`}
                      name="calendar-check"
                      label={data.name}
                      value={data.id}
                      checked={cal.id === data.id}
                      onChange={(e) => handleCalChange(e, data.name)}
                      className="mb-2"
                    />
                  ))}
                </div>
              ) : (
                <p className="text-muted">No calendars found. Please make sure you've connected your Google account.</p>
              )}
            </Card.Body>
          </Card>
        )}

        {pages.page2 && (
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="mb-3">Select Strava Club</Card.Title>
              <Card.Text>
                Choose the Strava club whose events you want to sync:
              </Card.Text>

              {stravaClubList.length > 0 ? (
                <div className="club-options mt-3" style={{ maxHeight: "300px", overflowY: "auto" }}>
                  {stravaClubList.map((data, i) => (
                    <Form.Check
                      key={i}
                      type="radio"
                      id={`club-${i}`}
                      name="strava-club"
                      label={data.name}
                      value={data.id}
                      checked={club.id === data.id.toString()}
                      onChange={(e) => handleClubChange(e, data.name)}
                      className="mb-2"
                    />
                  ))}
                </div>
              ) : (
                <p className="text-muted">No Strava clubs found. Please make sure you've connected your Strava account.</p>
              )}
            </Card.Body>
          </Card>
        )}

        {pages.page3 && (
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="mb-3">Confirm Your Choices</Card.Title>
              <Card.Text>
                Please review and confirm your selections:
              </Card.Text>

              <Table bordered hover className="mt-3">
                <thead className="bg-light">
                  <tr>
                    <th>Google Calendar</th>
                    <th>Strava Club</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{cal.name || "Not selected"}</td>
                    <td>{club.name || "Not selected"}</td>
                  </tr>
                </tbody>
              </Table>

              <p className="text-muted mt-3">
                After confirming, Strava club events will be synced to your selected Google Calendar.
              </p>
            </Card.Body>
          </Card>
        )}

        <Row className="mt-4">
          <Col xs={6}>
            {pageNo > 1 && (
              <Button
                variant="outline-secondary"
                onClick={goToPrev}
                style={{ width: "120px" }}
                disabled={isSubmitting}
              >
                Back
              </Button>
            )}
          </Col>
          <Col xs={6} className="text-end">
            <Button
              variant={pageNo === totalPages ? "success" : "primary"}
              onClick={nextPage}
              disabled={isNextDisabled() || isSubmitting}
              style={{
                width: "120px",
                backgroundColor: pageNo === totalPages ? "#28a745" : "rgb(255,113,0)",
                borderColor: pageNo === totalPages ? "#28a745" : "rgb(255,113,0)"
              }}
            >
              {isSubmitting ? (
                "Saving..."
              ) : (
                pageNo === totalPages ? "Confirm" : "Next"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
