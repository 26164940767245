import React from "react";
import { Container, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";

function Footer() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  // Function to handle navigation with scroll to top
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerContent}>
          <div className={styles.copyRight}>
            <span>ClubEventCal ©{currentYear}</span>
          </div>

          <div className={styles.footerLinks}>
            <NavLink
              as="span"
              onClick={() => handleNavigation('/privacy')}
              className={styles.footerLink}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              as="span"
              onClick={() => handleNavigation('/terms')}
              className={styles.footerLink}
            >
              Terms of Use
            </NavLink>
            <NavLink
              as="span"
              onClick={() => handleNavigation('/support')}
              className={styles.footerLink}
            >
              FAQ
            </NavLink>
          </div>

          <div className={styles.socialSection}>
            <div className={styles.coffeeButton}>
              <a href="https://www.buymeacoffee.com/clubeventcal" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                  alt="Buy Me A Coffee"
                  className={styles.coffeeImage}
                />
              </a>
            </div>

            <div className={styles.socialLinks}>
              <a href="https://www.instagram.com/clubeventcal" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                <i className="bi bi-instagram"></i>
              </a>
              <a href="https://x.com/clubeventcal" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                <i className="bi bi-twitter-x"></i>
              </a>
              <a href="https://www.strava.com/clubs/clubeventcal" target="_blank" rel="noopener noreferrer" className={styles.socialLink}>
                <i className="bi bi-strava"></i>
              </a>
            </div>

            <div className={styles.poweredBy}>
              <img
                alt="Powered by Strava"
                src="/w_powered_strava.png"
                loading="lazy"
                className={styles.stravaLogo}
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
