/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import SiteRoutes from "./components/SiteRoutes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { AuthProvider } from "./AuthContext";
import ScrollToTop from "./components/ScrollToTop";
import Config from "./Config";
import Maintenance from "./components/Maintenance";
import "./styles/variables.css";

function App() {
  // Reset any body background styles that might have been set by components
  useEffect(() => {
    return () => {
      // Clean up any background styles
      document.body.style.backgroundImage = '';
      document.body.style.backgroundPosition = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
      document.body.style.backgroundAttachment = '';

      // Clean up any body classes
      document.body.classList.remove('homepage-body');
      document.body.classList.remove('maintenance-body');
    };
  }, []);

  // Check if the site is in maintenance mode
  const isMaintenanceMode = Config.maintenanceMode;

  return (
    <AuthProvider>
      <div className="page-container">
        <ScrollToTop />
        <NavBar />
        <main>
          {isMaintenanceMode ? <Maintenance /> : <SiteRoutes />}
        </main>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
