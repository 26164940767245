import React from "react";
import { Container } from "react-bootstrap";
import "../../index.css";

export default function AboutUs() {
  return (
    <Container className="py-4">
      <div className="content-container">
        <h3 className="mb-4">About Us</h3>
        <p>
          At Club Event Cal, our mission is to simplify your fitness journey by seamlessly connecting your Strava Club Events with your Google Calendar. We understand that staying organized and up-to-date with group activities is crucial for maintaining a consistent workout routine and achieving your fitness goals. Our team of passionate developers and fitness enthusiasts created Club Event Cal to help athletes like you stay on top of their game.
        </p>
        <p>
          Founded in 2023, Club Event Cal was born out of the need to bridge the gap between fitness communities and personal organization. We identified the challenges athletes faced in keeping track of multiple club events and recognized the potential to streamline this process. Our solution, Club Event Cal, is designed to bring people together, foster accountability, and promote a healthy, active lifestyle.
        </p>
        <p>
          Our dedicated team is committed to constantly enhancing the user experience, ensuring seamless integration and reliability. We believe in the power of community and the importance of staying connected to like-minded individuals who share a passion for fitness. As we grow and evolve, we strive to maintain our core values: innovation, collaboration, and customer satisfaction.
        </p>
        <p>
          Join the Club Event Cal family today and experience the simplicity of integrating Strava Club Events into your Google Calendar. Let us help you stay organized, connected, and motivated on your path to fitness success. Together, we can elevate your performance and transform your lifestyle.
        </p>
        <p>
          Welcome to Club Event Cal—where fitness meets organization.
        </p>
      </div>
    </Container>
  );
}
