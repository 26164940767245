import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Layout.module.css';

/**
 * Layout component for consistent page structure
 * @param {Object} props Component props
 * @param {ReactNode} props.children Child components to render
 * @param {boolean} props.fluid Whether to use a fluid container
 * @param {string} props.className Additional CSS classes
 * @param {Object} props.style Additional inline styles
 */
const Layout = ({
  children,
  fluid = false,
  className = '',
  style = {}
}) => {
  return (
    <div className={styles.pageWrapper}>
      <Container fluid={fluid} className={`${styles.contentContainer} ${className}`} style={style}>
        {children}
      </Container>
    </div>
  );
};

export default Layout;