import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavLink, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../Config";
import { useAuth } from "../AuthContext";
import styles from "./NavBar.module.css";

function NavBar() {
  const { isAuthenticated, handleLogout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  // Function to handle navigation with scroll to top
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
    setExpanded(false);
  };

  // Add/remove class to body when navbar is expanded
  useEffect(() => {
    if (expanded) {
      document.body.classList.add('navbar-expanded');
    } else {
      document.body.classList.remove('navbar-expanded');
    }
  }, [expanded]);

  // Check if link is active
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
        expanded={expanded}
        onToggle={(expanded) => setExpanded(expanded)}
      >
        <Container>
          <Navbar.Brand
            as="span"
            onClick={() => handleNavigation('/')}
            className={styles.navbarBrand}
          >
            <Stack direction="horizontal" gap={3}>
              <img
                alt=""
                src="/cec-logo-3.png"
                width="40"
                height="40"
                loading="lazy"
                className={styles.brandLogo}
              />
              <div className={styles.branding}>
                <h1 className={styles.brandingTitle}>
                  <span className={styles.brandLogoPrimary}>C</span>
                  <span className={styles.brandLogoSecondary}>LUB</span>
                  <span className={styles.brandLogoTertiary}>E</span>
                  <span className={styles.brandLogoQuaternary}>VENT</span>
                  <span className={styles.brandLogoPrimary}>C</span>
                  <span className={styles.brandLogoSecondary}>AL</span>
                </h1>
              </div>
            </Stack>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className={`justify-content-end ${styles.navbarCollapse} ${expanded ? styles.navbarCollapseShow : ''}`}
            id="responsive-navbar-nav"
          >
            <Nav>
              <NavLink
                as="span"
                onClick={() => handleNavigation('/about_us')}
                className={`${styles.navLink} ${isActive('/about_us') ? styles.navLinkActive : ''}`}
              >
                About Us
              </NavLink>
              <NavLink
                as="span"
                onClick={() => handleNavigation('/support')}
                className={`${styles.navLink} ${isActive('/support') ? styles.navLinkActive : ''}`}
              >
                Support
              </NavLink>
              {isAuthenticated ? (
                <NavLink
                  as="span"
                  onClick={() => handleNavigation('/settings')}
                  className={`${styles.navLink} ${isActive('/settings') ? styles.navLinkActive : ''}`}
                >
                  Settings
                </NavLink>
              ) : null}
              {isAuthenticated ? (
                <Nav.Link
                  onClick={() => {
                    handleLogout();
                    setExpanded(false);
                  }}
                  className={styles.navLink}
                >
                  Logout
                </Nav.Link>
              ) : (
                !config.maintenanceMode && (
                  <Nav.Link
                    href={`${config.apiUrl}/authorize?platform=strava`}
                    className={styles.navLink}
                  >
                    Login
                  </Nav.Link>
                )
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
