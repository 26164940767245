import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Component that scrolls the window to the top when the route changes
 * and ensures consistent scrolling behavior across all pages
 * Place this component at the top level of your app
 */
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    // Ensure consistent scroll behavior for all pages
    document.documentElement.style.scrollBehavior = 'smooth';

    return () => {
      // Cleanup
      document.documentElement.style.scrollBehavior = '';
    };
  }, [pathname]);

  return null;
}

export default ScrollToTop;