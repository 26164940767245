import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AboutUs from "./about_us/AboutUs";
import GoogleAuthFail from "./google_authfail/GoogleAuthFail";
import StravaAuthFail from "./strava_authfail/StravaAuthFail";
import Settings from "./settings/Settings";
import NotFound from "./NotFound";
import HomePage from "./HomePage";
import Privacy from "./privacy/Privacy";
import Terms from "./terms/Terms";
import Support from "./support/Support";
import ProtectedRoute from "./ProtectedRoute";
import Maintenance from "./Maintenance";
import Layout from "./Layout";
import Config from "../Config";

export default function SiteRoutes() {
  const location = useLocation();

  // If maintenance mode is enabled, show the maintenance page for all routes
  if (Config.maintenanceMode) {
    return <Maintenance />;
  }

  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="about_us" element={<Layout><AboutUs /></Layout>} />
        <Route path="privacy" element={<Layout><Privacy /></Layout>} />
        <Route path="terms" element={<Layout><Terms /></Layout>} />
        <Route path="support" element={<Layout><Support /></Layout>} />

        <Route path="google_authfail" element={<Layout><GoogleAuthFail /></Layout>} />

        <Route path="strava_authfail" element={<Layout><StravaAuthFail /></Layout>} />

        <Route path="settings" element={
          <ProtectedRoute>
            <Layout>
              <Settings />
            </Layout>
          </ProtectedRoute>
        } />

        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Layout><NotFound /></Layout>} />
      </Routes>
    </>
  );
}
