import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/maintenance.css";
import bgImage from "../assets/bg.jpg";

export default function Maintenance() {
  const [bounce, setBounce] = useState(0);

  // Fun animation effect
  useEffect(() => {
    const interval = setInterval(() => {
      setBounce(prev => (prev + 1) % 5);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Add maintenance-body class and set background
  useEffect(() => {
    document.body.classList.add('maintenance-body');
    document.body.style.backgroundImage = `url(${bgImage})`;

    return () => {
      document.body.classList.remove('maintenance-body');
      document.body.style.backgroundImage = '';
    };
  }, []);

  return (
    <Container fluid className="p-0">
      <div className="maintenance-page">
        <Row className="justify-content-center text-center mx-0 w-100">
          <Col xs={11} sm={10} md={8} lg={6} className="maintenance-box rounded shadow-lg">
            <h1 className="fw-bold site-heading mb-4 responsive-heading">
              Training in Progress!
            </h1>

            <div className="tools-container">
              {[0, 1, 2, 3, 4].map(idx => (
                <div
                  key={idx}
                  className={`tool ${bounce === idx ? 'bounce' : ''}`}
                >
                  <i className={`fa fa-${
                    ['cog', 'wrench', 'hammer', 'screwdriver', 'magic'][idx]
                  } site-icon-${
                    ['primary', 'secondary', 'accent', 'highlight', 'dark'][idx]
                  }`}></i>
                </div>
              ))}
            </div>

            <p className="responsive-text mb-4">
              We're pushing our platform to the next level! 🚴‍♂️💪
            </p>

            <p className="text-muted mb-3 responsive-small">
              We'll be back with improved features to help you crush your fitness goals!
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
}