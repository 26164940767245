import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import "./support.css"; // Import custom CSS for accordion styling
import "../../index.css";

export default function Support() {
  return (
    <Container className="py-4">
      <div className="content-container">
        <div className="mb-5">
          <h3 className="mb-4">Support & FAQ</h3>

          <p className="lead mb-4">
            Welcome to the Club Event Cal Support Hub! We're dedicated to enhancing your fitness journey by
            ensuring a seamless connection between your Strava Club Events and Google Calendar.
          </p>

          <h4 className="mb-4">Frequently Asked Questions</h4>
        </div>

        <Accordion className="mb-5 shadow-sm custom-accordion">
          <Accordion.Item eventKey="0" className="border-0 mb-2">
            <Accordion.Header className="bg-light">How does Club Event Cal work?</Accordion.Header>
            <Accordion.Body>
              Club Event Cal creates a bridge between Strava and Google Calendar. After you authorize both platforms,
              our service automatically imports your Strava Club events into your selected Google Calendar,
              keeping your fitness schedule organized and accessible.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="border-0 mb-2">
            <Accordion.Header className="bg-light">How do I set up the service?</Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>Sign in with your Strava account</li>
                <li>Connect your Google Calendar</li>
                <li>Use our setup wizard to select which Strava Club and Google Calendar to connect</li>
                <li>That's it! Your events will start syncing automatically</li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="border-0 mb-2">
            <Accordion.Header className="bg-light">How often do events sync?</Accordion.Header>
            <Accordion.Body>
              Events are synced from Strava to Google Calendar <strong>every hour</strong>. This means new club events will appear in
              your Google Calendar within an hour of being created on Strava.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="border-0 mb-2">
            <Accordion.Header className="bg-light">Can I connect multiple Strava Clubs to my calendar?</Accordion.Header>
            <Accordion.Body>
              Currently, our service only supports connecting <strong>one Strava Club</strong> to <strong>one Google Calendar</strong> per user account.
              We're considering adding support for multiple club-to-calendar relationships in the future.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className="border-0 mb-2">
            <Accordion.Header className="bg-light">What happens if I change clubs on Strava?</Accordion.Header>
            <Accordion.Body>
              If you join a new club on Strava and want to sync its events instead, you'll need to update your settings in
              Club Event Cal. Just go to the Settings page and run the setup wizard again to select your new club.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="border-0 mb-2">
            <Accordion.Header className="bg-light">Can I filter which events to import?</Accordion.Header>
            <Accordion.Body>
              Unfortunately, this feature is not available yet. Our service will sync all events from your selected Strava Club
              to your Google Calendar. We're considering adding filtering options in future updates.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6" className="border-0 mb-2">
            <Accordion.Header className="bg-light">What information is included in the calendar events?</Accordion.Header>
            <Accordion.Body>
              Calendar events include the name of the Strava event, description, start time, end time (if available),
              and location. A link back to the original Strava event is also included for easy reference.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7" className="border-0 mb-2">
            <Accordion.Header className="bg-light">What if an event doesn't show up on my calendar?</Accordion.Header>
            <Accordion.Body>
              <p>If an event doesn't appear in your calendar, here are a few things to check:</p>
              <ul>
                <li>Verify that you've completed the setup process correctly</li>
                <li>Remember that syncing happens hourly, so new events may take up to an hour to appear</li>
                <li>Make sure you're looking at the correct Google Calendar</li>
                <li>Check your Settings page to confirm your club and calendar selections</li>
              </ul>
              <p>If you're still experiencing issues, please contact our support team.</p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8" className="border-0 mb-2">
            <Accordion.Header className="bg-light">Is there a mobile app available?</Accordion.Header>
            <Accordion.Body>
              Currently, Club Event Cal is a web-based service only. However, since we sync directly to your Google Calendar,
              you can view your synced events in any calendar app on your phone that connects to Google Calendar.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9" className="border-0 mb-2">
            <Accordion.Header className="bg-light">How do I revoke access?</Accordion.Header>
            <Accordion.Body>
              If you wish to revoke Club Event Cal's access to your Strava or Google accounts, you can do so directly
              through your Strava and Google account settings. For Strava, go to Settings {'->'} My Apps. For Google,
              visit myaccount.google.com {'->'} Security {'->'} Third-party apps with account access.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="mb-4">
          <h4 className="mb-4">Need More Help?</h4>
        </div>

        <Card className="border-0 shadow-sm mb-5">
          <Card.Body className="p-4">
            <div className="d-flex align-items-center mb-3">
              <span className="me-2" style={{ fontSize: "1.5rem" }}>📧</span>
              <h5 className="mb-0">Contact Us</h5>
            </div>
            <p>
              Can't find the answer you're looking for? Our dedicated team is ready to
              assist you with any queries or feedback.
            </p>
            <p className="mb-0">
              Email: <a href="mailto:support@clubeventcal.com" className="text-decoration-none">support@clubeventcal.com</a>
            </p>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}
